.plugin-page {
  &__input,
  &__textarea {
    border: 1px solid transparent;
    border-bottom: 1px solid #6b728030;

    &:hover {
      border: 1px solid #6b7280;

      & + .pencil-icon {
        display: inline-block;
      }
    }
  }

  &__input {
    padding: 5px 30px;
  }

  .pencil-icon {
    display: none;
  }

  .change-versions {
    width: 30px;
    height: 30px;
    animation: 0.5s out-in forwards;

    &:hover {
      animation: 0.5s in-out forwards;
    }
  }

  &__overview {
    .select-variants {
      min-height: 500px;
    }
  }

  .Toastify {
    &__progress-bar--success {
      background-color: #51b289;
    }

    &__toast--success {
      &.Toastify__toast-icon {
        svg {
          fill: #51b289;
        }
      }
    }
  }
}
