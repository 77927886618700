@keyframes close {
  0% {
    height: 0px;
  }
  100% {
    overflow: visible;
    height: 40px;
  }
}

@keyframes open {
  0% {
    overflow: hidden;
    height: 40px;
  }
  100% {
    overflow: hidden;
    height: 0px;
  }
}

@keyframes enlarge {
  0% {
    width: 38px;
  }
  100% {
    width: 260px;
  }
}

@keyframes reduce {
  0% {
    width: 260px;
  }
  100% {
    width: 36px;
  }
}

@keyframes in-out {
  0% {
    width: 30px;
  }
  100% {
    width: 150px;
  }
}

@keyframes out-in {
  0% {
    width: 150px;
  }
  100% {
    width: 30px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
