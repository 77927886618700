.categories {
  z-index: -1;

  &.closed {
    height: 0;
    overflow: hidden;
    animation: 0.5s close forwards;
  }

  &.open {
    height: 40px;
    overflow: visible;
    animation: 0.5s open forwards;
  }
}

.enlarge {
  animation: 0.5s enlarge forwards;
}

.reduce {
  animation: 0.5s reduce forwards;
}
